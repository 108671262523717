import {useState, useEffect, useCallback} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import IconNavigateNext from '@material-ui/icons/NavigateNext';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import { RadioGroup } from '@material-ui/core';


export const QuizCheckbox = (props) => {
    const [checked,setChecked] = useState(false);
    const answerType = props.answer.type;
    const evalValidity = (achecked) => {
        return (answerType === 'true' && achecked) ||
            (answerType === 'false' && ! achecked) || 
            (answerType === 'optional')
    }
    const [valid,setValid] = useState(
       evalValidity(false)
    );


    const answer = props.answer.answer;
    const validChanged = props.validChanged;
    const valueChanged = props.valueChanged;

    if( props.value !== undefined) {
        if( props.value !== answer)
            setChecked(props.value === answer)
    }

    
    useEffect(() => {
        //console.log("useffect validchanged " + answer + " = " + evalValidity(checked));
        //validChanged(answer,evalValidity(checked));
    },[]);


    const checkboxChanged = (event) => {
        setChecked(event.target.checked);
        let v = evalValidity(event.target.checked);            
        setValid(v);
        if( validChanged === undefined) return;
        console.log("calling validchanged  with" + answer + " = " + v);
        validChanged(answer, v );
    }

    const radioChanged = (event) => {
       valueChanged(answer);
       checkboxChanged(event);
    }


    return <Box align="left" marginLeft="1em"><FormControlLabel label={answer} color={valid ? 'green' : 'red'}
                control={props.singlechoice ? <Radio  onChange={radioChanged} value={answer}/> : <Checkbox  onChange={checkboxChanged}/> } 
            /></Box>
}                        




export const Question = (props) => {
    const[value,setValue] = useState();
    let ii =1;

    
    const valueChanged = (value) => {
        //
    }

    return <div className="question">
        <h3 className="title">{value}<div dangerouslySetInnerHTML={{__html: props.currentQuestion.question}} /></h3>
        <div className="right">
            {
                props.singlechoice ?
                    <RadioGroup name="question-choice-group" aria-label="gender">{
                    props.currentQuestion.answers.map( 
                        (answer) =>  <QuizCheckbox singlechoice="true" value={value} valueChanged={valueChanged} answer={answer} validChanged={props.validChanged} />
                    )}
                    </RadioGroup>
                :
                    props.currentQuestion.answers.map( 
                        (answer) =>  <QuizCheckbox answer={answer} validChanged={props.validChanged}   />
                    )
            }
        </div>
        <Box margin="1em 0">
            <Button endIcon={<IconNavigateNext />} variant="contained" color="primary" onClick={props.nextQuestion}>Zatwierdź i przejdź dalej</Button>
        </Box>
        {
            (props.currentQuestion.image !== undefined &&
            <div className="images">
                {props.currentQuestion.image.map( 
                    (image) => <div class="image">
                        <img src={image.src} alt={image.alt} key={image.src}/>
                        {image.description && <div className="description">{image.description}</div>}
                    </div>
                )}
            </div>
            )
        }
        {
            (props.currentQuestion.przypisy !== undefined) && props.currentQuestion.przypisy.map(
                (przyp) => <Box margin="1em 0" className="przypis">
                    <a name={przyp.anchor} class="przypisy">{ii++}</a> 
                    <span className="text" dangerouslySetInnerHTML={{__html: przyp.text}}></span>
                </Box>
            )
        }
    </div>;
}

export const Questions = (props) => {
    const [data, setData] = useState();
    const [ord, setOrd] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState();
    const [finished, setFinished] = useState( false);
    const [invalid, setInvalid] = useState( false);
    const [boxes, setBoxes] = useState();


    const randomQuestion = useCallback(() => {
        if( data !== undefined) {
            const i = (data.length && data[0].outofband) ? 0 : Math.floor(Math.random()*data.length);            
            setOrd(ord+1);
            setBoxes({});
            setCurrentQuestion(data[i]);
        }
    },[data]);
      
    useEffect(() => {
        if( data === undefined)
            setData(props.questions);
        randomQuestion();
    },[props.questions,randomQuestion,data]);


    const validate = () => {        
        if( boxes && Object.values(boxes).length)
            return Object.values(boxes).reduce( (a,b) => a && b);
        return false;
    };
  
    const nextQuestion = () => {
        if( validate() ) {
            if( props.checkpoint !== undefined)
                props.checkpoint({question: currentQuestion.question, answers:boxes, result:true});
            let ndata = data.filter((q) => q !== currentQuestion);
            setData(ndata);
            console.log("Checking lenght and ord", ndata.length, ord);
            if( (ndata.length === 0) || (ord >= props.setSize ) )
                setFinished(true);
            
            randomQuestion();
            

        } else {
            if( props.checkpoint !== undefined)
                props.checkpoint({question: currentQuestion.question, answers:boxes, result:false});

            setInvalid(true);
        }
    };

    const validChanged = (answer,valid) => {
        let x = {};
        x[answer] = valid;
        if( props.singlechoice) {
            setBoxes(x);
        } else {
            setBoxes( prev => { return {...prev , ...x}});
        }
        console.log("validchanged", answer, valid, JSON.stringify(boxes));
    };

    if( invalid ) {
        setTimeout(() => {
            setInvalid(false)
          }, props.penalty || 2000);
        return props.invalid || <div><CircularProgress /><div class="error quiz-error-answer">Nieprawidłowa odpowiedź</div></div>;
    }

    if( finished ) return props.next() || <div>Koniec</div>;

    if( currentQuestion === undefined)
        return <CircularProgress />;


    return  <Question validChanged={validChanged} 
                key={currentQuestion.question} 
                currentQuestion={currentQuestion} 
                singlechoice={props.singlechoice}
                nextQuestion={nextQuestion} />
};

export default Questions;