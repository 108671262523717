import React,{useState,useEffect,useContext} from 'react';
import Timer, { TimerProvider, TimerContext } from './Timer';
import Questions from './Questions';
import { TextField,Button, Box, FormControlLabel } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
const FINISHED = 'finished';
const FORM = 'form';

export const Quiz = (props) => {
    const [data,setData]=useState({ "title" : "Loading"});
    const [route,setRoute] = useState();
    const timerContext = useContext(TimerContext);
    const [timer,setTimer] = useState({});

    const [imie,setImie] = useState("");
    const [nazwisko, setNazwisko] = useState("");
    const [telefon, setTelefon] = useState("");
    const [zgoda,setZgoda] = useState(false);
    const [email, setEmail] = useState("");
    const [pwz, setPwz] = useState("");

    const pwzOK = pwz.match( /^[0-9]{7}$/) || pwz === "";
    const emailOK = email.match( /.+\@.+\..+/) || email === "";

    useEffect(() => {
        fetch(props.src,{
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }}).then( (reponse) => {
                 return reponse.json();
            }).then( (json) => {setData(json)});
    },[props.src]);

    const handleFinish = () => {
        setRoute(FINISHED);
        setTimer( timerContext.handleFinish());
    };

    if( route === FINISHED) {        
        return <Container component="main" maxWidth="xs" className="finished">
            <h1>Podsumowanie</h1>

            <div>Dziękujemy za odpowiedzi.</div>

            <p>Prosimy o przesłanie wyników i zakończenie quizu podaniem danych niezbędnych do przekazania nagrody.</p>

            <Box margin='2em 0'>
                <Button endIcon={<SendIcon />}  variant="contained" color="primary" fullWidth onClick={(ev) => setRoute(FORM)}>Dalej</Button>
            </Box>
        </Container>
    }

    if( route === FORM) {
        return <Container component="main" maxWidth="xs">
                <form method="post" action={props.action}>
                    <input type="hidden" name="time" value={timer.raw} />
                    <input type="hidden" name="timeFormatted" value={timer.formatted} />
                    <input type="hidden" name="imie" value={imie} />
                    <input type="hidden" name="nazwisko" value={nazwisko} />
                    <input type="hidden" name="telefon" value={telefon} />
                    <input type="hidden" name="email" value={email} />
                    <input type="hidden" name="pwz" value={pwz} />
                    <Box align="center" margin='2em'>
                        <Avatar>
                            <LockOutlinedIcon />
                        </Avatar>
                    </Box>
                    <Box align="left" margin='1em 0'>
                        <p>
                        {data.finishForm}
                        </p>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField variant="outlined" label="Imię" value={imie}  onChange={(el) => setImie(el.target.value)} required autoFocus/> 
                        </Grid>
                        <Grid item xs={12} sm={6}>           
                            <TextField variant="outlined"  label="Nazwisko" value={nazwisko}  onChange={(el) => setNazwisko(el.target.value)} required/>        
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant="outlined"  label="Numer telefonu (opcjonalnie)" value={telefon} onChange={(el) => setTelefon(el.target.value)} fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant="outlined"  label="Email"  error={!emailOK} value={email} onChange={(el) => setEmail(el.target.value)} required fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant="outlined"  label="PWZ"  error={!pwzOK} value={pwz} onChange={(el) => setPwz(el.target.value)} required fullWidth/>
                        </Grid>
                        <Grid item xs={12} align="left">
                            <FormControlLabel label={data.formAgreement || "Wyrażam zgodę na przetwarzanie danych osobowych...."}
                            control={<Checkbox variant="outlined"  fullwidth="true" label="Zgoda na przetwarzanie danych" checked={zgoda} onChange={(ev) => setZgoda(ev.target.checked)}/>} 
                            />
                        </Grid>
                    </Grid>            
                    <Box margin='2em 0'>
                        <Button endIcon={<SendIcon />} disabled={!zgoda || imie === '' || nazwisko === '' || pwz === '' || (! pwzOK) || (! emailOK) } 
                            variant="contained" color="primary" fullWidth 
                            type="submit">Wyślij</Button>
                    </Box>
                </form>
            </Container>
    }
            
    const checkpoint = (body) => {
        if( props.checkpoint !== undefined)
            fetch( props.checkpoint, { body:JSON.stringify(body), method: 'POST', keepAlive: true});
    };    

    return (
        <Container component="main" maxWidth="md">
            <h1>{data.title}{route}</h1>
            <Timer />
            <Questions questions={data.questions} 
                    penalty={data.penalty} 
                    next={handleFinish} 
                    setSize={data.setSize } 
                    checkpoint={checkpoint}
                    singlechoice={data.singlechoice}
                    >

            </Questions>
    </Container>);
};

export default Quiz;