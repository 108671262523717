import React,{useState, useRef, useEffect, useContext} from 'react';

export const TimerContext = React.createContext({});
export const TimerProvider = TimerContext.Provider;

export const Timer = (props) => {
  const [timer, setTimer] = useState(0);
  const increment = useRef(null)
  
  const timerContext = useContext(TimerContext);
  timerContext.handleFinish = () => {
    clearInterval(increment.current);
    return {
        raw: timer,
        formatted: formatTime()
    }
  }

  const formatTime = () => {
    const getSeconds = `0${(timer % 60)}`.slice(-2)
    const minutes = `${Math.floor(timer / 60)}`
    const getMinutes = `0${minutes % 60}`.slice(-2)
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2)

    return `${getHours} : ${getMinutes} : ${getSeconds}`
  }

 
  const handleStart = () => {
    increment.current = setInterval(() => {
      setTimer((timer) => timer + 1)
    }, 1000)
  }

  useEffect(() =>{
    if( props.autostart !== false) {
      handleStart();  
    }
  },[props.autostart])


  return (
      <div className='stopwatch-card'>
        <p>{formatTime()}</p>        
      </div>
  );
};

export default Timer;