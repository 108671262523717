import logo from './logo.svg';
import './App.css';
import Quiz from './Quiz';
import {useState} from 'react';
import { Button } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

function App(props) {
  const [start,setStart] = useState(false);
  if( start )
    return (
      <div className="App">
        <header className="App-header">
              <Quiz src={ props.quiz || "/quiz/eazyhaler.json" }  action={props.redirect || "dziekujemy.html"} checkpoint="logger.php" />
        </header>      
      </div>
    );


  return (
    <div className="App">
      <header className="App-header">
                <h2>Szanowni Państwo,</h2>
                <p>Serdecznie zapraszamy do wzięcia udziału w Quizie Easyhaler.</p>
 
                <p>Prosimy o udzielenie odpowiedzi na siedem pytań jednokrotnego wyboru. Należy prawidłowo odpowiedzieć na wszystkie pytania i zaakceptować wybór, aby przejść do kolejnego pytania.
                Udzielenie błędnej odpowiedzi powoduje powtórne wyświetlenie tego samego pytania, dopóki odpowiedź nie będzie prawidłowa.</p>


                <Button endIcon={<SendIcon />}  variant="contained" color="primary" onClick={(el) => setStart(true)} >Zaczynamy</Button>
      </header>      
    </div>
  );
}

export default App;
